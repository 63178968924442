export const months = [
    {
        key: 0,
        text: 'JANUARY',
        value: 'JANUARY',
    },
    {
        key: 1,
        text: 'FEBRUARY',
        value: 'FEBRUARY',
    },
    {
        key: 2,
        text: 'MARCH',
        value: 'MARCH',
    },
    {
        key: 3,
        text: 'APRIL',
        value: 'APRIL',
    },
    {
        key: 4,
        text: 'MAY',
        value: 'MAY',
    },
    {
        key: 5,
        text: 'JUNE',
        value: 'JUNE',
    },
    {
        key: 6,
        text: 'JULY',
        value: 'JULY',
    },
    {
        key: 7,
        text: 'AUGUST',
        value: 'AUGUST',
    },
    {
        key: 8,
        text: 'SEPTEMBER',
        value: 'SEPTEMBER',
    },
    {
        key: 9,
        text: 'OCTOBER',
        value: 'OCTOBER',
    },
    {
        key: 10,
        text: 'NOVEMBER',
        value: 'NOVEMBER',
    },
    {
        key: 11,
        text: 'DECEMBER',
        value: 'DECEMBER',
    },
    {
        key: 12,
        text: 'JANUARY',
        value: 'JANUARY',
    }
]

export const days = [
{key: 1,text: '1',value: '1'},
{key: 2,text: '2',value: '2'},
{key: 3,text: '3',value: '3'},
{key: 4,text: '4',value: '4'},
{key: 5,text: '5',value: '5'},
{key: 6,text: '6',value: '6'},
{key: 7,text: '7',value: '7'},
{key: 8,text: '8',value: '8'},
{key: 9,text: '9',value: '9'},
{key: 10,text: '10',value: '10'},
{key: 11,text: '11',value: '11'},
{key: 12,text: '12',value: '12'},
{key: 13,text: '13',value: '13'},
{key: 14,text: '14',value: '14'},
{key: 15,text: '15',value: '15'},
{key: 16,text: '16',value: '16'},
{key: 17,text: '17',value: '17'},
{key: 18,text: '18',value: '18'},
{key: 19,text: '19',value: '19'},
{key: 20,text: '20',value: '20'},
{key: 21,text: '21',value: '21'},
{key: 22,text: '22',value: '22'},
{key: 23,text: '23',value: '23'},
{key: 24,text: '24',value: '24'},
{key: 25,text: '25',value: '25'},
{key: 26,text: '26',value: '26'},
{key: 27,text: '27',value: '27'},
{key: 28,text: '28',value: '28'},
{key: 29,text: '29',value: '29'},
{key: 30,text: '30',value: '30'},
{key: 31,text: '31',value: '31'}
]

export const years = [
{key: "2024",text: "2024",value:"2024"},
{key: "2023",text: "2023",value:"2023"},
{key: "2022",text: "2022",value:"2022"},
{key: "2021",text: "2021",value:"2021"},
{key: "2020",text: "2020",value:"2020"},
{key: "2019",text: "2019",value:"2019"},
{key: "2018",text: "2018",value:"2018"},
{key: "2017",text: "2017",value:"2017"},
{key: "2016",text: "2016",value:"2016"},
{key: "2015",text: "2015",value:"2015"},
{key: "2014",text: "2014",value:"2014"},
{key: "2013",text: "2013",value:"2013"},
{key: "2012",text: "2012",value:"2012"},
{key: "2011",text: "2011",value:"2011"},
{key: "2010",text: "2010",value:"2010"},
{key: "2009",text: "2009",value:"2009"},
{key: "2008",text: "2008",value:"2008"},
{key: "2007",text: "2007",value:"2007"},
{key: "2006",text: "2006",value:"2006"},
{key: "2005",text: "2005",value:"2005"},
{key: "2004",text: "2004",value:"2004"},
{key: "2003",text: "2003",value:"2003"},
{key: "2002",text: "2002",value:"2002"},
{key: "2001",text: "2001",value:"2001"},
{key: "2000",text: "2000",value:"2000"},
{key: "1999",text: "1999",value:"1999"},
{key: "1998",text: "1998",value:"1998"},
{key: "1997",text: "1997",value:"1997"},
{key: "1996",text: "1996",value:"1996"},
{key: "1995",text: "1995",value:"1995"},
{key: "1994",text: "1994",value:"1994"},
{key: "1993",text: "1993",value:"1993"},
{key: "1992",text: "1992",value:"1992"},
{key: "1991",text: "1991",value:"1991"},
{key: "1990",text: "1990",value:"1990"},
{key: "1989",text: "1989",value:"1989"},
{key: "1988",text: "1988",value:"1988"},
{key: "1987",text: "1987",value:"1987"},
{key: "1986",text: "1986",value:"1986"},
{key: "1985",text: "1985",value:"1985"},
{key: "1984",text: "1984",value:"1984"},
{key: "1983",text: "1983",value:"1983"},
{key: "1982",text: "1982",value:"1982"},
{key: "1981",text: "1981",value:"1981"},
{key: "1980",text: "1980",value:"1980"},
{key: "1979",text: "1979",value:"1979"},
{key: "1978",text: "1978",value:"1978"},
{key: "1977",text: "1977",value:"1977"},
{key: "1976",text: "1976",value:"1976"},
{key: "1975",text: "1975",value:"1975"},
{key: "1974",text: "1974",value:"1974"},
{key: "1973",text: "1973",value:"1973"},
{key: "1972",text: "1972",value:"1972"},
{key: "1971",text: "1971",value:"1971"},
{key: "1970",text: "1970",value:"1970"},
{key: "1969",text: "1969",value:"1969"},
{key: "1968",text: "1968",value:"1968"},
{key: "1967",text: "1967",value:"1967"},
{key: "1966",text: "1966",value:"1966"},
{key: "1965",text: "1965",value:"1965"},
{key: "1964",text: "1964",value:"1964"},
{key: "1963",text: "1963",value:"1963"},
{key: "1962",text: "1962",value:"1962"},
{key: "1961",text: "1961",value:"1961"},
{key: "1960",text: "1960",value:"1960"},
{key: "1959",text: "1959",value:"1959"},
{key: "1958",text: "1958",value:"1958"},
{key: "1957",text: "1957",value:"1957"},
{key: "1956",text: "1956",value:"1956"},
{key: "1955",text: "1955",value:"1955"},
{key: "1954",text: "1954",value:"1954"},
{key: "1953",text: "1953",value:"1953"},
{key: "1952",text: "1952",value:"1952"},
{key: "1951",text: "1951",value:"1951"},
{key: "1950",text: "1950",value:"1950"},
{key: "1949",text: "1949",value:"1949"},
{key: "1948",text: "1948",value:"1948"},
{key: "1947",text: "1947",value:"1947"},
{key: "1946",text: "1946",value:"1946"},
{key: "1945",text: "1945",value:"1945"},
{key: "1944",text: "1944",value:"1944"},
{key: "1943",text: "1943",value:"1943"},
{key: "1942",text: "1942",value:"1942"},
{key: "1941",text: "1941",value:"1941"},
{key: "1940",text: "1940",value:"1940"},
{key: "1939",text: "1939",value:"1939"},
{key: "1938",text: "1938",value:"1938"},
{key: "1937",text: "1937",value:"1937"},
{key: "1936",text: "1936",value:"1936"},
{key: "1935",text: "1935",value:"1935"},
{key: "1934",text: "1934",value:"1934"},
{key: "1933",text: "1933",value:"1933"},
{key: "1932",text: "1932",value:"1932"},
{key: "1931",text: "1931",value:"1931"},
{key: "1930",text: "1930",value:"1930"},
{key: "1929",text: "1929",value:"1929"},
{key: "1928",text: "1928",value:"1928"},
{key: "1927",text: "1927",value:"1927"},
{key: "1926",text: "1926",value:"1926"},
{key: "1925",text: "1925",value:"1925"},
{key: "1924",text: "1924",value:"1924"},
{key: "1923",text: "1923",value:"1923"},
{key: "1922",text: "1922",value:"1922"}
]

export const brgys = [
{key: "BARANGAY 1 (POB.)",text: "BARANGAY 1 (POB.)",value:"BARANGAY 1 (POB.)"},
{key: "BARANGAY 2 (POB.)",text: "BARANGAY 2 (POB.)",value:"BARANGAY 2 (POB.)"},
{key: "BARANGAY 3 (POB.)",text: "BARANGAY 3 (POB.)",value:"BARANGAY 3 (POB.)"},
{key: "BARANGAY 4 (POB.)",text: "BARANGAY 4 (POB.)",value:"BARANGAY 4 (POB.)"},
{key: "BARANGAY 5 (POB.)",text: "BARANGAY 5 (POB.)",value:"BARANGAY 5 (POB.)"},
{key: "BARANGAY 6 (POB.)",text: "BARANGAY 6 (POB.)",value:"BARANGAY 6 (POB.)"},
{key: "BARANGAY 7 (POB.)",text: "BARANGAY 7 (POB.)",value:"BARANGAY 7 (POB.)"},
{key: "BAGONG KALSADA",text: "BAGONG KALSADA",value:"BAGONG KALSADA"},
{key: "BANADERO",text: "BANADERO",value:"BANADERO"},
{key: "BANLIC",text: "BANLIC",value:"BANLIC"},
{key: "BARANDAL",text: "BARANDAL",value:"BARANDAL"},
{key: "BATINO",text: "BATINO",value:"BATINO"},
{key: "BUBUYAN",text: "BUBUYAN",value:"BUBUYAN"},
{key: "BUCAL",text: "BUCAL",value:"BUCAL"},
{key: "BUNGGO",text: "BUNGGO",value:"BUNGGO"},
{key: "BUROL",text: "BUROL",value:"BUROL"},
{key: "CAMALIGAN",text: "CAMALIGAN",value:"CAMALIGAN"},
{key: "CANLUBANG",text: "CANLUBANG",value:"CANLUBANG"},
{key: "HALANG",text: "HALANG",value:"HALANG"},
{key: "HORNALAN",text: "HORNALAN",value:"HORNALAN"},
{key: "KAY-ANLOG",text: "KAY-ANLOG",value:"KAY-ANLOG"},
{key: "LAGUERTA",text: "LAGUERTA",value:"LAGUERTA"},
{key: "LA MESA",text: "LA MESA",value:"LA MESA"},
{key: "LAWA",text: "LAWA",value:"LAWA"},
{key: "LECHERIA",text: "LECHERIA",value:"LECHERIA"},
{key: "LINGGA",text: "LINGGA",value:"LINGGA"},
{key: "LOOC",text: "LOOC",value:"LOOC"},
{key: "MABATO",text: "MABATO",value:"MABATO"},
{key: "MAKILING",text: "MAKILING",value:"MAKILING"},
{key: "MAPAGONG",text: "MAPAGONG",value:"MAPAGONG"},
{key: "MASILI",text: "MASILI",value:"MASILI"},
{key: "MAUNONG",text: "MAUNONG",value:"MAUNONG"},
{key: "MAYAPA",text: "MAYAPA",value:"MAYAPA"},
{key: "PACIANO RIZAL",text: "PACIANO RIZAL",value:"PACIANO RIZAL"},
{key: "PALINGON",text: "PALINGON",value:"PALINGON"},
{key: "PALO-ALTO",text: "PALO-ALTO",value:"PALO-ALTO"},
{key: "PANSOL",text: "PANSOL",value:"PANSOL"},
{key: "PARIAN",text: "PARIAN",value:"PARIAN"},
{key: "PRINZA",text: "PRINZA",value:"PRINZA"},
{key: "PUNTA",text: "PUNTA",value:"PUNTA"},
{key: "PUTING LUPA",text: "PUTING LUPA",value:"PUTING LUPA"},
{key: "REAL",text: "REAL",value:"REAL"},
{key: "SUCOL",text: "SUCOL",value:"SUCOL"},
{key: "SAIMSIM",text: "SAIMSIM",value:"SAIMSIM"},
{key: "SAMPIRUHAN",text: "SAMPIRUHAN",value:"SAMPIRUHAN"},
{key: "SAN CRISTOBAL",text: "SAN CRISTOBAL",value:"SAN CRISTOBAL"},
{key: "SAN JOSE",text: "SAN JOSE",value:"SAN JOSE"},
{key: "SAN JUAN",text: "SAN JUAN",value:"SAN JUAN"},
{key: "SIRANG LUPA",text: "SIRANG LUPA",value:"SIRANG LUPA"},
{key: "TULO",text: "TULO",value:"TULO"},
{key: "TURBINA",text: "TURBINA",value:"TURBINA"},
{key: "ULANGO",text: "ULANGO",value:"ULANGO"},
{key: "UWISAN",text: "UWISAN",value:"UWISAN"},
{key: "MAJADA LABAS",text: "MAJADA LABAS",value:"MAJADA LABAS"}
]

export const requirements = [
    {
        code: 'INR',
        requirements: [
            'REQ1',
            'REQ2',
            'REQ3'
        ]
    }
]

export const getDateTimeNow = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();
    var hh = today.getHours();
    var mnt = today.getMinutes();

    return {
        d: `${mm}-${dd}-${yyyy}`,
        t: `${hh}:${mnt}`
    }
}

export const PAGES = {
    FRONTDESK: "/frontdesk",
    PRINTING: "/print",
    RELEASING: "/releasing",
    MOA: "/moa",
    REPORTS: "/reports",
    LOGS: "/logs",
    LOGIN: "/login",
    WINDOW: "/window",
    KIOSK: "/kiosk",
    CONTROLLER: "/controller",
    MEDS: "/medicine",
    HIT: "/hit",
    SOCIAL: "/socialcase"
}

export const civilstatus = [
    {key: "SINGLE",text: "SINGLE",value:"SINGLE"},
    {key: "MARRIED",text: "MARRIED",value:"MARRIED"},
    {key: "WIDOW",text: "WIDOW",value:"WIDOW"},
]

export const status = [
    {key: "FOR UPDATING",text: "FOR UPDATING",value:"FOR UPDATING"},
    {key: "PRE-APPROVED",text: "PRE-APPROVED",value:"PRE-APPROVED"},
    {key: "DISAPPROVED",text: "DISAPPROVED",value:"DISAPPROVED"},
    {key: "FOR PRINTING",text: "FOR PRINTING",value:"FOR PRINTING"},
    {key: "RELEASED",text: "RELEASED",value:"RELEASED"},
    {key: "DONE",text: "DONE",value:"DONE"},
]